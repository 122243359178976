import React from 'react';
import { Wrapper } from './footer.styled';
import { Container } from '../../../components/web/container/container';
import { FooterContent } from './content/footer-content';

export const Footer: React.FC = () => {
    return (
        <Wrapper>
            <Container>
                <FooterContent />
            </Container>
        </Wrapper>
    );
};
