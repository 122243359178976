import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper, Inner } from './header.styled';
import { routes } from '../../../config/routes';
import { Navigation } from './navigation/header-navigation';
import { Account } from './account/header-account';
import { Container } from '../../../components/web/container/container';
import { Logo } from '../../../components/app/logo/logo';

export const Header: React.FC = observer(() => {
    return (
        <Wrapper>
            <Container>
                <Inner>
                    <Logo href={routes.web.index} white />

                    <Navigation />

                    <Account />
                </Inner>
            </Container>
        </Wrapper>
    );
});
