import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @media ${theme.breakpoints.md.min} {
            gap: 2rem;
        }
    `}
`;
