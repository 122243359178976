import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconPlus: React.FC = () => (
    <Icon viewBox="0 0 10 10" fill="none">
        <path
            d="M5 1v8M1 5h8"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
