import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Wrapper } from './header-account.styled';
import { useRootStore } from '../../../../providers/root-store-provider';
import { routes } from '../../../../config/routes';
import { IconNext } from '../../../../components/app/icons/next';
import { Button } from '../../../../components/app/form/button/button';

export const Account: React.FC = observer(() => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { authStore } = rootStore;
    const router = useRouter();

    useEffect(() => {
        if (router.query.login === 'true') {
            authStore.authDialogStore.setLoginActive();
        }
    }, [router.query]);

    return (
        <Wrapper>
            {rootStore.cookieService.hasJwtToken() && (
                <Button nativeLink href={routes.app.index} icon={<IconNext />} iconGap="xsmall" variant="secondary">
                    {t('pages.layout.header.goToApp')}
                </Button>
            )}
            {!rootStore.cookieService.hasJwtToken() && (
                <>
                    <Button
                        variant={'white'}
                        variation={'transparent'}
                        onClick={() => authStore.authDialogStore.setLoginActive()}
                    >
                        {t('pages.layout.header.signIn')}
                    </Button>
                    <Button
                        variant="secondary"
                        size={{ sm: 'small', md: 'medium' }}
                        onClick={() => {
                            authStore.authDialogStore.setSignupActive();
                        }}
                    >
                        {t('pages.layout.header.tryItForFree')}
                    </Button>
                </>
            )}
        </Wrapper>
    );
});
