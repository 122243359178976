import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    min-height: 12.2rem;
`;

export const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Buttons = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 2rem;

        button {
            color: ${theme.palette.color.white.main};
        }

        @media (max-width: 768px) {
            display: none;
        }
    `,
);

export const Border = styled.div(
    ({ theme }) => css`
        width: 0.1rem;
        height: 2.4rem;
        display: inline-block;
        background: ${theme.palette.color.white.opacity.low};
    `,
);
