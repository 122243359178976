import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './faq-list.styled';
import { FaqItem } from '../item/faq-item';

interface FaqListProps {
    limit?: number;
}

export const FaqList: React.FC<FaqListProps> = ({ limit }) => {
    const { t } = useTranslation();
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    interface FaqItemProps {
        id: string;
        question: string;
        answer: string;
        imageUrl?: string;
    }

    const faqData: FaqItemProps[] = Object.values(t('pages.faq.items', { returnObjects: true }));

    const faqItems = limit ? faqData.slice(0, limit) : faqData;

    useEffect(() => {
        const hash = window.location.hash.replace('#', '');
        if (hash) {
            const index = faqData.findIndex((item) => item.id === hash);
            if (index !== -1) {
                setOpenIndex(index);
                document.getElementById(hash)?.scrollIntoView();
            }
        }
    }, []);

    return (
        <Wrapper>
            {faqItems.map((item, index) => (
                <FaqItem
                    key={item.id}
                    id={item.id}
                    question={item.question}
                    answer={item.answer}
                    imageUrl={item.imageUrl}
                    isOpen={openIndex === index}
                    onClick={() => {
                        handleToggle(index);
                        window.history.pushState({}, '', `#${item.id}`);
                    }}
                />
            ))}
        </Wrapper>
    );
};
