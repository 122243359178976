import React, { useState } from 'react';
// import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Wrapper, Content, Hero } from './layout.styled';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { LoginDialog } from '../../../components/web/dialogs/account/login-dialog';
import { SignUpDialog } from '../../../components/web/dialogs/account/sign-up-dialog';
import { ForgottenPasswordDialog } from '../../../components/web/dialogs/account/forgotten-password-dialog';
import { ResetPasswordDialog } from '../../../components/web/dialogs/account/reset-password-dialog';
import { Alerts } from '../../../components/web/alerts/alerts';

interface LayoutPropsInterface {
    children: React.ReactNode | Array<React.ReactNode>;
    intro?: React.ReactNode;
    header?: React.ReactNode;
}

export const Layout: React.FC<LayoutPropsInterface> = ({ children, intro, header }) => {
    const router = useRouter();
    const [email, setEmail] = useState('');

    const status = router.query.status ? router.query.status.toString() : undefined;
    const action = router.query.action ? router.query.action.toString() : undefined;
    const token = router.query.token ? router.query.token.toString() : undefined;

    return (
        <Wrapper>
            <Hero>
                {header || <Header />}
                {intro && intro}
            </Hero>
            <Content>{children}</Content>
            <Footer />

            <Alerts email={email} />

            <LoginDialog status={status} action={action} token={token} setEmail={setEmail} />
            <SignUpDialog status={status} action={action} />
            <ForgottenPasswordDialog status={status} action={action} />
            <ResetPasswordDialog status={status} action={action} />
        </Wrapper>
    );
};
