import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    padding: 12rem 0;
    scroll-margin-top: -8rem;
`;

export const Tariffs = styled.div`
    display: flex;
    gap: 3rem;
    width: 100%;
    flex-direction: column;
    align-items: center;

    @media (min-width: 750px) {
        flex-direction: row;
        justify-content: center;
        align-items: unset;
    }
`;

export const More = styled.div`
    display: flex;
    margin-top: 6rem;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
