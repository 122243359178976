import React from 'react';
import { Wrapper, Action } from './headline.styled';
import { GapSizeType } from '../../../theme/type/gap-type';
import { PaletteColorType } from '../../../theme/type/palette-type';

interface HeadlinePropsInterface {
    tag?: React.ElementType | keyof JSX.IntrinsicElements;
    level?: number | string;
    marginTop?: GapSizeType;
    gap?: GapSizeType;
    children: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    inverse?: boolean;
    action?: React.ReactNode;
    variant?: PaletteColorType;
}

export const Headline: React.FC<HeadlinePropsInterface> = ({
    tag,
    level,
    marginTop = 'none',
    gap = 'medium',
    children,
    align = 'left',
    inverse,
    action,
    variant = 'secondary',
}) => {
    return (
        <Wrapper
            as={tag || `h${level}`}
            level={`h${level}`}
            gap={gap}
            marginTop={marginTop}
            align={align}
            inverse={inverse}
            variant={variant}
        >
            {children}
            {action && <Action>{action}</Action>}
        </Wrapper>
    );
};
