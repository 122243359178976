import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Wrapper } from './header-navigation.styled';
import { routes } from '../../../../config/routes';

export const Navigation: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Link href={routes.web.featureList}>
                <a href={routes.web.featureList}>{t('pages.layout.header.features')}</a>
            </Link>
            <Link href={routes.web.pricing}>
                <a>{t('pages.layout.header.pricing')}</a>
            </Link>
            <Link href={routes.web.contact}>
                <a>{t('pages.layout.header.contact')}</a>
            </Link>
        </Wrapper>
    );
});
