import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{
    isOpen: boolean;
}>`
    ${({ theme, isOpen }) => css`
        overflow: hidden;
        background-color: #f7fcff;
        border: 0.1rem solid #f7fcff;
        transition: all 0.3s ease-in-out;
        border-radius: ${theme.radius.medium};

        ${isOpen &&
        css`
            background-color: ${theme.palette.color.white.main};
            border-color: ${theme.palette.color.secondary.opacity.high};
            margin: 1rem 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        `}
    `}
`;

export const Question = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{
    isOpen: boolean;
}>`
    ${({ theme, isOpen }) => css`
        padding: 1.6rem;
        gap: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${isOpen ? theme.palette.color.secondary.lighten : theme.palette.color.secondary.main};
        font-weight: 700;
        cursor: pointer;

        @media ${theme.breakpoints.md.min} {
            font-size: 2.2rem;
            line-height: 3rem;
            padding: 4rem;
        }
    `}
`;

export const Answer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{
    isOpen: boolean;
}>`
    ${({ theme, isOpen }) => css`
        display: ${isOpen ? 'block' : 'none'};
        transition: all 0.5s ease-out;
        overflow: hidden;
        padding: ${isOpen ? '1.6rem' : '0 1.6rem'};

        a {
            color: ${theme.palette.color.primary.main};
            text-decoration: underline;
            transition: color ${theme.base.transition};

            &:hover {
                color: ${theme.palette.color.primary.light};
                text-decoration: none;
            }
        }

        @media ${theme.breakpoints.md.min} {
            padding: ${isOpen ? '4rem' : '0 4rem'};
        }

        ${isOpen &&
        css`
            grid-template-rows: 1fr;
            padding-bottom: 1.6rem;

            @media ${theme.breakpoints.md.min} {
                padding-bottom: 4rem;
            }
        `}
    `}
`;

export const AnswerInner = styled.div`
    ${({ theme }) => css`
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: ${theme.palette.color.secondary.main};
        overflow: hidden;

        @media ${theme.breakpoints.md.min} {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    `}
`;
