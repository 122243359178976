/* eslint-disable prettier/prettier */
import React from 'react';
import Image from 'next/image';
import { Wrapper, Question, Answer, AnswerInner } from './faq-item.styled';
import { IconButton } from '../../../app/icon/button/icon-button';
import { IconPlus } from '../../../app/icons/plus';
import { IconMinus } from '../../../app/icons/minus';

interface FaqItemProps {
    id: string;
    question: string;
    answer: string;
    imageUrl?: string;
    isOpen?: boolean;
    onClick?: () => void;
}

export const FaqItem: React.FC<FaqItemProps> = ({ question, answer, imageUrl, isOpen = false, onClick, id }) => {
    return (
        <Wrapper isOpen={isOpen} id={id}>
            <Question isOpen={isOpen} onClick={onClick}>
                {question}
                <IconButton
                    size={{ xs: 'xsmall', md: 'small' }}
                    variant={isOpen ? 'negative' : 'secondary'}
                    icon={isOpen ? <IconMinus /> : <IconPlus />}
                />
            </Question>
            <Answer isOpen={isOpen}>
                <AnswerInner>
                    <div dangerouslySetInnerHTML={{ __html: answer }} />
                    {imageUrl && isOpen && (
                        <div>
                            <Image
                                src={imageUrl}
                                alt={`${question} image`}
                                layout="responsive"
                                width="100%"
                                height="50%"
                                objectFit="contain"
                            />
                        </div>
                    )}
                </AnswerInner>
            </Answer>
        </Wrapper>
    );
};
