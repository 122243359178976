import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.palette.color.white.main};
        min-height: 100vh;
    `}
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['backgroundImage'].includes(prop),
})<{
    backgroundImage?: string;
}>`
    ${({ theme, backgroundImage }) => css`
        flex: 1;

        ${backgroundImage &&
        css`
            background-image: url(/images/${backgroundImage});
            background-position: 50% 8rem;
            background-repeat: no-repeat;

            @media ${theme.breakpoints.xl.max} {
                background-size: 130% auto;
            }
        `}
    `}
`;

export const Hero = styled.div`
    ${({ theme }) => css`
        background: url('/images/intro/background.jpg') no-repeat;
        background-size: cover;
        color: ${theme.palette.color.white.main};
    `}
`;
