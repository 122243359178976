import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7.2rem;

        a {
            font-size: 1.4rem;
            line-height: 1.4rem;
            font-weight: 700;
            color: ${theme.palette.color.white.main};

            &:hover {
                text-decoration: underline;
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    `,
);
